import { Button } from "@mui/material";
import React from "react";
import {
  landingPageActionButtonMessage,
  landingPageActionButtonNumber,
} from "../../content";
type ScheduleNowButtonProps = {
  buttonText?: string;
};
export const ScheduleNowButton: React.FC<ScheduleNowButtonProps> = ({
  buttonText = "Agende já",
}) => (
  <Button
    variant="contained"
    size="large"
    component="a"
    href={`https://wa.me/${landingPageActionButtonNumber}?text=${landingPageActionButtonMessage}`}
    sx={{ minWidth: 200, color: "#FFFFFF" }}
    target="_blank"
  >
    {buttonText}
  </Button>
);
