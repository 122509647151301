import { Box, Divider, Typography } from "@mui/material";
import { pages } from "../../content";
import { ScheduleNowButton } from "../atoms/ScheduleNowButton";
import { TreatmentCard } from "../atoms/TreatmentCard";

export default function ContentSection() {
  return (
    <Box>
      {pages.map((page) => (
        <Box
          sx={{
            paddingX: { xs: 4, md: 16 },
            paddingY: 4,
            backgroundColor: page.backgroundColor,
          }}
        >
          <Box
            sx={{
              display: { md: "flex" },
            }}
          >
            <Box
              sx={{
                width: { md: page.paragraphImage ? "50%" : "100%" },
              }}
            >
              <Divider
                sx={{
                  width: 100,
                  borderColor: "#D8AE9C",
                  marginBottom: 2,
                }}
              />
              <Typography variant="h4" sx={{ marginBottom: 4 }}>
                {page.title}
              </Typography>
              {page.paragraphs?.length ? (
                <Box
                  sx={{
                    paddingRight: { md: 4 },
                  }}
                >
                  {page.paragraphs?.map((paragraph) => (
                    <Typography
                      sx={{
                        paddingBottom: 2,
                        textAlign: "justify",
                        color: "text.secondary",
                      }}
                    >
                      {paragraph}
                    </Typography>
                  ))}
                </Box>
              ) : null}
            </Box>
            {page.paragraphImage && (
              <Box
                sx={{
                  width: { md: "50%" },
                  textAlign: { md: "center" },
                  height: "500px",
                }}
              >
                <img alt="paragraph" src={page.paragraphImage} height="500px" />
              </Box>
            )}
          </Box>

          {page.items?.length ? (
            <Box sx={{ display: { lg: "flex" }, width: "100%" }}>
              {page.items?.map((item) => (
                <TreatmentCard
                  title={item.title}
                  paragraph={item.paragraph}
                  icon={item.icon}
                />
              ))}
            </Box>
          ) : null}

          {page.list?.length ? (
            <Box sx={{ display: { sm: "flex" }, flexWrap: "wrap" }}>
              {page.list.map((item) => (
                <Typography
                  sx={{
                    color: "text.secondary",
                    paddingY: 1,
                    width: { sm: "100%", md: "50%" },
                    display: "list-item",
                  }}
                >
                  {item}
                </Typography>
              ))}
            </Box>
          ) : null}
          {page.subSection?.length
            ? page.subSection?.map((item) => (
                <Box sx={{ width: "100%", marginBottom: 2 }}>
                  <Typography
                    variant="h5"
                    sx={{
                      marginBottom: 2,
                    }}
                  >
                    {item.title}
                  </Typography>
                  {item.paragraphs.map((paragraph) => (
                    <Typography
                      sx={{
                        color: "text.secondary",
                      }}
                    >
                      {paragraph}
                    </Typography>
                  ))}
                </Box>
              ))
            : null}

          {page.conclusion && (
            <Typography sx={{ marginBottom: 4, color: "text.secondary" }}>
              {" "}
              {page.conclusion}
            </Typography>
          )}
          {page.action && (
            <Box sx={{ textAlign: { xs: "center", md: "initial" } }}>
              <ScheduleNowButton buttonText={page.action} />
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
}
