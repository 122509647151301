import React from "react";
import "./App.css";
import ContentSection from "./components/molecules/ContentSection";
import FooterSection from "./components/molecules/FooterSection";
import LandingSection from "./components/molecules/LandingSection";
import withRoot from "./withRoot";

function App() {
  return (
    <React.Fragment>
      <LandingSection />
      <ContentSection />
      <FooterSection />
    </React.Fragment>
  );
}

export default withRoot(App);
