import whoIAmImage from "./images/who-am-i-image.jpeg";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import WorkIcon from "@mui/icons-material/Work";

type SubItem = {
  title: string;
  paragraph: string;
  icon?: any;
};
type Page = {
  name: string;
  title: string;
  backgroundColor: string;
  textColor: string;
  paragraphs: string[];
  paragraphImage?: string;
  items?: SubItem[];
  conclusion?: string;
  action?: string;
  subSection?: Page[];
  list?: string[];
};

export const landingPageTitle = "Desenvolva sua habilidade de comunicação";
export const landingPageDescription =
  "Conquiste sua melhor performance ao falar em público!";

export const landingPageActionButtonMessage =
  "Olá!%20Gostaria%20de%20mais%20informações%20sobre%20a%20consulta.";
export const landingPageActionButtonNumber = "5548991233887";
export const cellphone = "48991233887";

export const pages: Page[] = [
  {
    name: "Sobre",
    title: "Conheça a Dra. Lauanda Santos",
    backgroundColor: "#FFFFFF",
    textColor: "#d8ae9c",
    paragraphs: [
      "Formada pela Universidade Federal do Rio de Janeiro (UFRJ), fez sua primeira especialização na modalidade Residência Multiprofissional em Saúde no Hospital Universitário da Universidade Federal de Santa Catarina (HU/UFSC) com ênfase em Alta Complexidade. É também especialista em Disfagia pelo Conselho Federal de Fonoaudiologia e mestranda no Programa de Pós Graduação em Fonoaudiologia da UFSC.",
      "Possui cursos nas áreas de Voz e Disfagia, com formação em Oratória e atendimento especializado em Pós Operatório de Cirurgias de Cabeça e Pescoço. Com anos de experiência, tem grande paixão por ajudar indivíduos a aprimorarem suas habilidades de comunicação e expressão oral. Apresenta abordagem personalizada e técnicas comprovadas que têm ajudado profissionais de diversas áreas a se destacarem em apresentações, entrevistas e discursos.",
    ],
    paragraphImage: whoIAmImage,
  },
  {
    name: "Atendimento",
    title: "Serviços Oferecidos",
    backgroundColor: "#f8f3f1",
    textColor: "#d8ae9c",
    paragraphs: [],
    items: [
      {
        title: "Treinamento em Oratória",
        paragraph:
          "Aprenda a controlar o nervosismo, melhorar dicção, entonação e postura corporal para falar com confiança e impacto.",
        icon: RecordVoiceOverIcon,
      },
      {
        title: "Aprimoramento da Voz",
        paragraph:
          "Desenvolva sua voz para transmitir autoridade e carisma em suas apresentações. Também é realizado atendimento a demandas vocais de canto ou pós operatório, nódulos, entre outros.",
        icon: GraphicEqIcon,
      },
      {
        title: "Preparação para Entrevistas",
        paragraph:
          "Domine técnicas para comunicar suas ideias de forma clara e persuasiva em entrevistas profissionais.",
        icon: WorkIcon,
      },
      {
        title: "Comunicação Assertiva",
        paragraph:
          "Melhore suas habilidades interpessoais, aprendendo a expressar suas ideias com clareza e respeito.",
        icon: ConnectWithoutContactIcon,
      },
    ],
    subSection: [
      {
        name: "extraServices",
        title: "Demais atendimentos realizados:",
        backgroundColor: "#f8f3f1",
        textColor: "#d8ae9c",
        paragraphs: [
          "Disfagia: Câncer de cabeça e pescoço, presbifagia, doenças neurodegenerativas, doenças neuromusculares, acidente vascular encefálico e outros.",
        ],
      },
    ],
  },
  {
    name: "Benefícios da Oratória",
    title: "Benefícios da Oratória",
    backgroundColor: "#FFFFFF",
    textColor: "#d8ae9c",
    paragraphs: [
      "Comunicar-se de forma eficaz não apenas fortalece sua presença profissional, mas também abre portas para novas oportunidades. A oratória é uma habilidade essencial em diversas áreas da vida, desde o ambiente corporativo até situações sociais.",
    ],
    list: [
      "Comunicação eficaz",
      "Confiança pessoal",
      "Liderança",
      "Persuasão",
      "Capacidade de negociação",
      "Gerenciamento de estresse",
      "Desenvolvimento profissional",
      "Comunicação interpessoal",
      "Capacidade de influência",
      "Autoconhecimento",
      "Networking",
      "Impacto social",
    ],
  },
  {
    name: "Como funcionam as consultas?",
    title: "Como funcionam as consultas?",
    backgroundColor: "#f8f3f1",
    textColor: "#d8ae9c",
    paragraphs: [],
    subSection: [
      {
        name: "Avaliação e Diagnóstico Fonoaudiológico",
        title: "Avaliação e Diagnóstico Fonoaudiológico",
        paragraphs: [
          "Envolve a análise de saúde geral, anamnese, história clínica, hábitos de voz e alimentares e estilo de vida, bem como avaliação de motricidade orofacial, voz e deglutição (adaptado conforme motivo da consulta).",
        ],

        backgroundColor: "#FFFFFF",
        textColor: "#d8ae9c",
      },
      {
        name: "Intervenção Fonoaudiológica",
        title: "Intervenção Fonoaudiológica",
        paragraphs: [
          "De modo individualizado e com participação ativa do paciente, os objetivos são tratados a partir dos achados da avaliação e queixas. O paciente receberá orientações e exercícios personalizados.",
        ],

        backgroundColor: "#FFFFFF",
        textColor: "#d8ae9c",
      },
      {
        name: "Acompanhamento",
        title: "Acompanhamento",
        paragraphs: [
          "Consultas semanais para novas condutas e exercícios, conforme evolução. É oferecido suporte por WhatsApp e e-mail.",
        ],
        backgroundColor: "#FFFFFF",
        textColor: "#d8ae9c",
      },
    ],
  },
];

export const clinics = [
  {
    name: "Fergus Otorrinos Associados",
    address: "R. Irmã Benwarda, 53 - Centro, Florianópolis - SC, 88015-270",
    contact: "(48) 3024-0050",
    contactRaw: "4830240050",
    website: "https://fergusotorrinos.com.br/",
  },
];

export const socialMedias = [
  {
    name: "phone",
    link: `tel:${cellphone}`,
  },
  {
    name: "instagram",
    link: "https://www.instagram.com/fonolauandasantos/",
  },
  {
    name: "whatsapp",
    link: `https://wa.me/${landingPageActionButtonNumber}?text=${landingPageActionButtonMessage}`,
  },
];
