import { Card, CardContent, Icon, Typography } from "@mui/material";
import React from "react";
type TreatmnetCardProps = {
  title: string;
  paragraph: string;
  icon?: any;
};
export const TreatmentCard: React.FC<TreatmnetCardProps> = ({
  title,
  paragraph,
  icon,
}) => (
  <Card
    variant="outlined"
    sx={{
      width: { lg: "25%" },
      marginRight: 2,
      padding: 2,
      backgroundColor: "white",
      marginBottom: 2,
    }}
  >
    <CardContent>
      <Icon
        component={icon}
        sx={{
          fontSize: 60,
          textAlign: "center",
          width: "100%",
          marginBottom: 2,
        }}
      ></Icon>
      <Typography
        variant="h5"
        sx={{ marginBottom: 2, textAlign: "center", fontWeight: 600 }}
      >
        {title}
      </Typography>
      <Typography sx={{ textAlign: "center", color: "text.secondary" }}>
        {paragraph}
      </Typography>
    </CardContent>
  </Card>
);
