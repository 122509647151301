import { Box, IconButton, Link, Typography } from "@mui/material";
import { socialMedias } from "../../content";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
import footerLogo from "../../content/images/footer-logo.png";
import { clinics } from "../../content";

export default function FooterSection() {
  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        paddingX: { xs: 4, md: 16 },
        paddingY: 4,
        display: { md: "flex" },
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
      }}
    >
      <Box>
        <img src={footerLogo} className="App-logo" alt="logo" height="250px" />
      </Box>
      <Box
        sx={{
          color: "#ffffff",
        }}
      >
        {clinics.map((clinic) => (
          <Box>
            <Typography variant="h6">{clinic.name}</Typography>
            <Typography variant="body1">{clinic.address}</Typography>
            <Typography variant="body1">
              <Link
                sx={{ color: "#FFFFFF", cursor: "pointer" }}
                href={`tel:${clinic.contact}`}
              >
                {clinic.contact}
              </Link>
            </Typography>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          color: "#ffffff",
        }}
      >
        <Typography>Entre em contato diretamente:</Typography>

        <Box>
          {socialMedias.map((socialMedia, index) => (
            <IconButton
              aria-label={`social-media-${index}`}
              sx={{ color: "#FFFFFF" }}
              onClick={() => window.open(socialMedia.link, "_blank")}
            >
              {socialMedia.name === "phone" && <PhoneIcon />}
              {socialMedia.name === "instagram" && <InstagramIcon />}
              {socialMedia.name === "whatsapp" && <WhatsAppIcon />}
            </IconButton>
          ))}
        </Box>
        <Box>
          <Typography variant="caption">
            Criado e desenvolvido por FDR Tecnologia LTDA.
          </Typography>
        </Box>
        <Box>
          <Typography variant="caption">Todos direitos reservados.</Typography>
        </Box>
      </Box>
    </Box>
  );
}
